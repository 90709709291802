<template lang="pug">
.review(
  :class="{'review_full': full}"
)
  .review__top
    nuxt-img.review__img(
      lazy="true"
      quality="80"
      format="webp"
      :src="data.img"
    )
    span.review__author <strong>{{ data.name }}</strong><br>{{ podcast }}
  p.review__text {{ text }}
  a.review__read(
    v-if="readMore"
    @click="readMoreClickHandler"
  ) {{ $t('links.read_more') }}
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    },

    full: {
      type: Boolean,
      default: false
    },

    readMore: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    text () {
      if (this.full) {
        return this.data.text
      }
      return this.data.text.slice(0, 100) + '...'
    },

    podcast () {
      if (this.data.podcast && this.data.podcast.length > 25 && !this.full) {
        return this.data.podcast.slice(0, 25) + ' ...'
      }
      return this.data.podcast
    }
  },

  methods: {
    readMoreClickHandler () {
      this.$emit('open', this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
.review {
  width: 100%;
  border-radius: 8px;
  padding: $gap * 1.5;
  background-color: #25163A;

  &_full {
    max-width: 520px;
  }

  &__top {
    display: grid;
    position: flex;
    grid-gap: $gap;
    align-items: center;
    grid-template-columns: 40px 1fr;
  }

  &__img {
    width: 40px;
    height: 40px;
    border-radius: 9px;
  }

  &__author {
    color: #fff;
    font-size: 14px;

    strong {
      color: #fff;
    }
  }

  &__text {
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 13px;
    line-height: 150%;
    margin-bottom: 5px;
  }

  &__read {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
