<template lang="pug">
.review-io
  span.review-io__title {{ title }}
  .review-io__stars
    StarIcon.review-io__stars-icon
    StarIcon.review-io__stars-icon
    StarIcon.review-io__stars-icon
    StarIcon.review-io__stars-icon
    StarIcon.review-io__stars-icon
  .review-io__rating
    strong 4.92&nbsp;
    span {{ $t('single_words.rating') }}
  ReviewIoLogo.review-io__logo
</template>

<script>
import StarIcon from '@/components/icons/Star'
import ReviewIoLogo from '@/components/svg/logo/review-io'

export default {
  components: {
    StarIcon,
    ReviewIoLogo
  },

  props: {
    short: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    title () {
      if (!this.short) {
        return this.$t('modules.review_io_badge.title_pricing_plans')
      } else {
        return this.$t('pages.ssr_landing.rating.badge_io_title')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.review-io {
  width: 100%;
  display: flex;
  padding: $gap $gap * 1.5;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  background: #2E1F42;
  flex-direction: column;

  &__title {
    color: #fff;
    font-size: 14px;
    line-height: 1.2;
    font-weight: bold;
    text-align: center;
    margin-bottom: 12px;
  }

  &__stars {
    display: flex;
    grid-gap: 8px;
    margin-bottom: 12px;

    &-icon {
      width: 18px;
    }
  }

  &__rating {
    margin-bottom: 12px;

    span {
      color: #fff;
      font-size: 14px;
    }

    strong {
      color: #fff;
      font-size: 16px;
    }
  }
}
</style>
